
.page--change--pass {
    height: 100vh;
    width: 100%;
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
}

.change--pass--container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button--change--pass {
    padding: 8px;
    border: none;
    background-color: transparent;
    color: var(--primary-color-claret, #451B17);
}

.button--change--pass:hover {
    color: var(--primary-color-hover, #992000);
}

.change--pass--text,
.change--pass--tittle {
    width: 400px;
    color: var(--primary-color-claret, #451B17);
    font-weight: 500;
    padding-bottom: 24px;
}
.change--pass--text {
    font-family: var(--secondary-font, "Manrope");
    font-size: smaller;
}

.change--pass--input {
    width: 400px;
    padding: 8px;
    margin: 2px;
    border: none;
    outline: none;
    color: var(--neutral-color-dark-gray, #A6A8AA);
}

.change--pass--input:hover {
    color: var(--primary-color-hover, #992000);
}

/* Change password */
.button--change--pass {
    width: 400px;
    height: 36px;
    background-color: var(--primary-color-orange-sanguine, #FF3300);
    color: var(--neutral-color-white, #FFFFFF);
    font-family: var(--secondary-font, "Manrope");
    text-transform: uppercase;
    padding: 8px;
    margin-top: 32px;
    border: none;
}

.button--change--pass:hover {
    background-color: var(--primary-color-hover, #992000);
    color: var(--neutral-color-white, #FFFFFF);
}

/* Cancel */
.button--change--pass--cancel {
    padding: 8px;
    border: none;
    background-color: transparent;
    color: var(--primary-color-orange-sanguine, #FF3300); 
}

.button--change--pass--cancel:hover {
    color: var(--primary-color-hover, #992000);
}