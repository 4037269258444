/* CustomSelect.css */
.custom--select--container {
    position: relative;
    display: inline-block;
}
  
.custom--select--box {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--primary-color-orange-sanguine, #FF3300);
    padding: 5.1px;
    background-color: var(--neutral-color-white, #FFFFFF);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: var(--secondary-font);
    white-space: nowrap;
    color: var(--primary-color-orange-sanguine, #FF3300);
    height: 32px;
}
  
.custom--select--box .red--text {
    padding-right: 20px;
}

.custom--select--box--img {
    position: relative;
    margin-top: 2px;
    right: 5px; 
}

.custom--select--box--img--zona--name {
    max-width: 20px;
}

.custom--select--dropdown {
    position: absolute;
    top: 37px;
    right: 0;
    width: 100%;
    min-width: 150px;
    overflow-y: auto;
    background-color: var(--neutral-color-white, #FFFFFF);
    z-index: 10;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Apply a subtle shadow */
}
  
.custom--select--dropdown li {
    white-space: nowrap;
    padding: 8px;
    cursor: pointer;
    font-family: var(--secondary-font);
    background-color: var(--neutral-color-white, #FFFFFF);
    color: var(--primary-color-claret, #451B17);
    text-transform: uppercase;
    font-weight: 800;
    font-size: 12px;
    line-height: 20px;
}

.custom--select--dropdown li:hover {
    background-color: #f0f0f0;
}

.custom--select--dropdown::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.custom--select--dropdown::-webkit-scrollbar-thumb {
    background-color: #000000; 
    border-radius: 2px; 
}

.custom--select--dropdown::-webkit-scrollbar-track {
    background-color: transparent; 
}

.custom--select--dropdown::-webkit-scrollbar-button {
    display: none;
}

.custom--select--dropdown img {
    padding-top: 5px;
    margin-right: 5px;
}

/* Only FireFox */
@-moz-document url-prefix() {
    .custom--select--dropdown {
        scrollbar-width: thin;
        scrollbar-color: #000000 #ffffff;
    }
} 