
.layer--control {
    z-index: 800;
    position: absolute;
    top: 10px;
    left: 50px;
    background-color: var(--neutral-color-white, #FFFFFF);
    width: 364px;
    padding: 12px;
    padding-bottom: 20px;
}

.layer--control--tittle {
    color: var(--primary-color-orange-sanguine, #FF3300);
    padding-top: 12px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.layer--control--divider {
    border: 1px solid var(--primary-color-pale-peach, #FFE0D1);
}

