

.page--login {
    height: 100vh;
    width: 100%;
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
}

.login--container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login--note {
    margin-top: 40px;
    width: 350px;
    font: var(--secondary-font, "Manrope");
    font-size: x-small;
    color: var(--neutral-color-darker-gray, #5B5E62);

    /* line-height:px; */
}

.button--forgot--password {
    padding: 8px;
    border: none;
    background-color: transparent;
    color: var(--primary-color-claret, #451B17);
}

.button--forgot--password:hover {
    color: var(--primary-color-hover, #992000);
}

.logo--name {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

