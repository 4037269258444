
.dropdown-container {
    position: relative;
    display: inline-block;
    height: 35px;
}
  
.dropdown--button {
    /* display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer; */
    height: 32px;
}

.button-image {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.dropdown--list {
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #ddd;
    /* width: 100%; */
}

.dropdown--option {
    padding: 12px 16px;
    cursor: pointer;
    background-color: white;
}

.dropdown-option:hover {
    background-color: #f1f1f1;
}