

.NavBar {
    z-index: 800;
    position: absolute;
    top: 10px;
    left: 10px;
    height: 97vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.NavBar--Top {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.NavBar--Top button {
    width: 32px;
    height: 32px;
    background-color: var(--primary-color-claret, #451B17);
    border: none;
    padding: 8px;
}

.NavBar--Top .button--tooltip:hover {
    background-color: var(--primary-color-hover, #992000);
}

.NavBar--Top .button--tooltip--text {
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
    padding: 8px;
    margin-left: 5px;
    position: relative;
    top: 20px;
}

.button--tooltip--graph--true,
.button--tooltip--layer--true {
    background-color: var(--primary-color-orange-sanguine, #FF3300) !important;
}

.NavBar--true .button--tooltip--text {
    visibility: hidden !important;
}

.logo {
    width: 32px;
}

.NavBar--Top img {
    width: 16px;
    height: 16px;
}