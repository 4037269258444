
.dashboard--container {
    z-index: 900;
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 10px;
    left: 50px;
    padding: 12px;
    background-color: var(--neutral-color-white, #FFFFFF);
    /* width: 90%; */
}

.dashboard--tittle {
    color: var(--primary-color-orange-sanguine, #FF3300);
    /* padding: 12px; */
    /* padding-top: 12px; */
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* .layer--control--divider {
    border: 1px solid var(--primary-color-pale-peach, #FFE0D1);
} */

.dashboard--scroll {
    width: 100%; 
    /* height: 200px; */
    height: 80vh;
    overflow-y: scroll;       
    overflow-x: scroll; /*hidden;*/       
    padding: 12px; 
}

.dashboard--scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.dashboard--scroll::-webkit-scrollbar-thumb {
    background-color: #000000; 
    border-radius: 2px; 
}

.dashboard--scroll::-webkit-scrollbar-track {
    background-color: transparent; 
}

.dashboard--scroll::-webkit-scrollbar-button {
    display: none;
}

/* Only FireFox */
@-moz-document url-prefix() {
    .dashboard--scroll {
        scrollbar-width: thin;
        scrollbar-color: #000000 #ffffff;
    }
}

/* .graphics--container {
    width: 80%;
    height: 200px;
} */