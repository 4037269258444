

.layer--name {
    width: 100%;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
}

.layer--name--tittle--true {
    color: var(--primary-color-claret, #451B17);
    font-weight: 400;
}

.layer--name--tittle--false {
    color: var(--neutral-color-dark-gray, #A6A8AA);
} 

.layer--name--end {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 2px;
}

.img--arrow {
    height: 18px;
}