:root{
    /* Primary Color */
    --primary-color-claret: #451B17;
    --primary-color-claret-70: #451B17B2;
    --primary-color-hover: #992000;
    --primary-color-orange-sanguine: #FF3300;
    --primary-color-pale-peach: #FFE0D1;
    --primary-color-pale-peach-50: #FFEFE8;

    /* Neutral Color */
    --neutral-color-deep-black: #000D12;
    --neutral-color-darker-gray: #5B5E62;
    --neutral-color-dark-gray: #A6A8AA;
    --neutral-color-mid-gray: #DADADC;
    --neutral-color-mid-gray-50: #EDEDED;
    --neutral-color-white: #FFFFFF;

    /* Green */
    --green-color-racing-green: #08424A;
    --green-color-mid-green: #4C9B80;
    --green-color-park-green: #CFEADB;

    /* Blue */
    --blue-color-deep-blue: #1D3661;
    --blue-color-adriatic-blue: #5E83C8;
    --blue-color-pale-blue: #CBE3FF;

    /* Opacity */
    --opacity-color-pale-peach-80: #FFE0D1CC;
    --opacity-color-pale-peach-50: #FFE0D180;
    --opacity-color-pale-peach-12: #FFE0D11F;

    /* Fonts */
    --primary-font: "PT Serif";
    --secondary-font: "Manrope";
}