
.password--input--container {
    position: relative;
    display: inline-block;
}

.button--visibility--true,
.button--visibility--false {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}