
.toggle {
    margin-top: 3px;
    width: 28px;
    height: 14px;
    border: 3px solid var(--neutral-color-mid-gray, #DADADC); 
    border-radius: 25px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;
}

.toggled .toggle-button {
    background-color: var(--green-color-mid-green, #4C9B80);
}

.toggled {
    border-color: var(--green-color-park-green, #CFEADB);
}

.toggle-button {
    width: 6px;
    height: 6px;
    background-color: var(--neutral-color-dark-gray, #A6A8AA);
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s;
}

.toggle.toggled .toggle-button {
    left: 14px;
}