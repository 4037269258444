
.dashboar--control--container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 30px;
}

.dashboar--control--event {
    min-width: 200px;
}

.button--dashboar--control--download,
.button--dashboar--control--light,
.button--dashboar--control--dark {
    height: 32px;
    /* min-width: 200px; */
    font-family: var(--secondary-font, "Manrope"); 
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px;
    margin: 4px;
}

.button--dashboar--control--dark {
    background-color: var(--primary-color-claret, #451B17);
    color: var(--neutral-color-white, #FFFFFF);
    border: none;
}

.button--dashboar--control--light {
    background-color: var(--neutral-color-white, #FFFFFF);
    color: var(--primary-color-claret, #451B17);
    border: 1px solid var(--neutral-color-mid-gray, #DADADC);
    padding-top: 7px;
}

.button--dashboar--control--light:hover { 
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
}

.button--dashboar--control:hover {
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
}

.dashboar--control--filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
}

/* .dashboar--control--select--box {
    padding: 2px; 
    margin: 2px; 
    height: 36px;
} */

.button--dashboar--control--download {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    gap: 5px;
    background-color: var(--primary-color-orange-sanguine, #FF3300);
    color: var(--neutral-color-white, #FFFFFF);
    border: none;
}