



.login--tittle {
    width: 350px;
    color: var(--primary-color-claret, #451B17);
    font-weight: 500;
    padding-bottom: 24px;
}

.login--form {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.login--input {
    width: 350px;
    padding: 8px;
    border: none;
    outline: none;
    color: var(--neutral-color-dark-gray, #A6A8AA);
}

.login--focus--true,
.login--input:focus {
    color: var(--primary-color-claret, #451B17);
}

.login--input:hover {
    color: var(--primary-color-hover, #992000);
}

.error-message {
    max-width: 350px;
    color: var(--primary-color-orange-sanguine, #FF3300);
    font: var(--secondary-font, "Manrope");
    font-size: small;
    padding: 8px;
}

.button--login--submit {
    height: 36px;
    background-color: var(--primary-color-orange-sanguine, #FF3300);
    color: var(--neutral-color-white, #FFFFFF);
    font: var(--secondary-font, "Manrope");
    text-transform: uppercase;
    padding: 8px;
    margin-top: 32px;
    border: none;
}

