
.zone--control {
    display: flex;
    gap: 4px;
}

.select--zone--table {
    min-width: 200px;
}

.zone--name {
    min-width: 270px;
}