
/* CustomSelect.css */
.custom--Datepicker--container {
    position: relative;
    display: inline-block;
    z-index: 1000;
}
  
.custom--Datepicker {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--primary-color-orange-sanguine, #FF3300);
    padding: 5.1px;
    background-color: var(--neutral-color-white, #FFFFFF);
    font-weight: 400;
    font-size: 16px; /* 16 */
    line-height: 20px;
    font-family: var(--secondary-font);
    white-space: nowrap;
    color: var(--primary-color-orange-sanguine, #FF3300);
    /* height: 34px; */
}
  
.custom--Datepicker .red--text {
    /* padding-right: 20px; */
    min-width: 140px;
}

.custom--Datepicker--default {
    display: flex;
    justify-content: space-between;
}

.custom--Datepicker--resalt {
    display: flex;
    justify-content: space-between;
    background: var(--primary-color-pale-peach-50, #FFEFE8);
    padding-left: 5px;
    width: 90%;
}

.custom--Datepicker--reset {
    position: relative;
    /* margin-top: 2px; */
    /* width: 10px;  */
    right: 20px;
    padding-left: 5px;
    padding-right: 2px;
}

.custom--Datepicker--reset:hover {
    content: url("./img/closed_hover.svg");
}

.custom--Datepicker--img {
    position: absolute;  /* relative; */
    margin-top: 2px;
    /* width: 10px; */
    margin-left: 5px;
    right: 4px; 
}

.custom--Datepicker--pop {
    position: absolute;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */ /* Aplica una sombra sutil */
}

.dashboar--control--custom--Datepicker--from {
    right: 0;
}

.react-datepicker {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Aplica una sombra sutil */
}

.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow, 
.react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__navigation-icon::before {
    border-color: var(--primary-color-orange-sanguine, #FF3300) !important;
}

.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow, 
.react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__navigation-icon::before:hover {
    border-color: var(--primary-color-hover, #992000) !important;
}

.react-datepicker__day-name,
h2.react-datepicker__current-month {
    color: var(--primary-color-claret, #451B17) !important;
    text-transform: capitalize;
}

h2.react-datepicker__current-month:hover {
    color:  var(--primary-color-orange-sanguine, #FF3300) !important;
}

.react-datepicker__header {
    border-bottom: none;
    background-color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    color: var(--primary-color-hover, #992000) !important;
    background-color: var(--opacity-color-pale-peach-50, #FFE0D180) !important;
}

.react-datepicker__year-dropdown {
    background-color: var(--neutral-color-white, #FFFFFF);
    font-size: 14px;
}

.react-datepicker__year-dropdown--scrollable {
    /* width: 100%;  */
    /* height: 200px; */
    /* height: 80vh; */
    overflow-y: scroll;       
    /*overflow-x: scroll; /*hidden;   */   
    padding: 12px; 
}

.react-datepicker__year-dropdown--scrollable::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.react-datepicker__year-dropdown--scrollable::-webkit-scrollbar-thumb {
    background-color: #000000; 
    border-radius: 2px; 
}

.react-datepicker__year-dropdown--scrollable::-webkit-scrollbar-track {
    background-color: transparent; 
}

.react-datepicker__year-dropdown--scrollable::-webkit-scrollbar-button {
    display: none;
}

/* Only FireFox */
@-moz-document url-prefix() {
    .react-datepicker__year-dropdown--scrollable {
        scrollbar-width: thin;
        scrollbar-color: #000000 #ffffff;
    }
} 

.react-datepicker__year-option:hover {
    background-color: #FF3300;
}

.button--tooltip--text {
    visibility: hidden;
    z-index: 9999;
    position: absolute;
    top: 30px;
    right: 20px;
    background: var(--primary-color-pale-peach-50, #FFEFE8);
    color: #000000;
    padding: 8px;
    font-size: 16px; /* 16 */
    line-height: 20px;
}

.custom--Datepicker--resalt:hover .button--tooltip--text {
    visibility: visible;
}