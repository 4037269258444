
.top--setting {
    z-index: 800;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.top--setting button {
    width: 32px;
    height: 32px;
    background-color: var(--primary-color-claret, #451B17);
    border: none;
    /* padding-top: 3px; */
    padding: 8px;
}

.top--setting img {
    width: 16px;
    height: 16px;
}