
* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: "PT Serif", "Manrope";
    scroll-behavior: smooth;
}

/* body {
    margin: 0px;
} */

.leaflet-marker-icon {
    overflow: visible; /* Evita que se recorten los iconos del marcador */
    margin: 0; /* Asegura que no haya margen inesperado */
    padding: 0; /* Asegura que no haya relleno inesperado */
    border: none; /* Elimina cualquier borde no deseado */
}

/* .layer-control:target .button--tooltip--text {
    visibility: hidden !important;
} */

.app--body:has(.layer-control:visible) .NavBar > .button--tooltip--text {
    visibility: hidden !important;
}