
.info-box {
    z-index: 800;
    position: absolute;
    bottom: 10px;
    right: 120px;
    display: flex;
    gap: 5px;
}

.info-box p {
    padding: 10px;
}

.leaflet-control-scale {
    bottom: 10px !important;
}

/* Move the zoom control to the middle of the map vertically and to the right*/
.leaflet-top.leaflet-right {
    top: 50%;
    transform: translateY(-50%);
  }
  
.leaflet-control-zoom {
    display: flex;
    flex-direction: column;
    gap: 2px;
    border: none !important;
}

.leaflet-bottom,
.leaflet-top {
    z-index: 800 !important;
}

/* Change the icons and the separation between the buttons */
.leaflet-control-zoom a,
.leaflet-control-zoom a:focus {
    background: var(--primary-color-claret, #451B17) !important;
    color: var(--neutral-color-mid-gray, #DADADC) !important;
    width: 32px !important; /* Button width */
    height: 32px !important; /* Button height */
    line-height: 30px !important; /* Line height to center text vertically */
    border-radius: 1px !important;
}

.leaflet-control-attribution {
    display: none;
}
  
.leaflet-container a.leaflet-popup-close-button {
    display: none;
}

/* Estilo del enlace */
/* .leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    position: relative;
    color: blue;
    text-decoration: none;
} */

/* Estilo del tooltip */
/* .leaflet-control-zoom-in:hover::after,
.leaflet-control-zoom-out:hover::after {
    content: attr(date-title); /* Usamos un atributo personalizado 
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
    color: black;
    padding: 8px;
    margin-right: 5px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
    font: var(--primary-font, "PT Serif") !important;
    /* text-indent: 0; 
    font-size: 16px;
    line-height: 20px;
} */