.graphics--container {
    display: flex;
    justify-content: center;
    /* height: 50vh;
    min-height: 50vh; */
    /* width: 50%; */
}

.koko {
    width: 60%;
}