

.dash--board--table--header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.dash--board--table--body--cell,
.dash--board--table--header--row {
    flex: 1;
    color: var(--primary-color-claret, #451B17);
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px;
    padding-left: 20px;
    margin: 4px;
}

.dash--board--table--body--row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.highlight {
    background-color: var(--primary-color-orange-sanguine, #FF3300);
}

