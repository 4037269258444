

.button--tooltip--text {
    visibility: hidden;
    z-index: 999;
}

.button--imagen--tooltip:hover .button--tooltip--text {
    visibility: visible;
}
