
.forgot--password--text,
.forgot--password--tittle {
    width: 400px;
    color: var(--primary-color-claret, #451B17);
    font-weight: 500;
    padding-bottom: 24px;
}
.forgot--password--text {
    font-family: var(--secondary-font, Manrope);
    font-size: smaller;
}

.forgot--password--input {
    width: 400px;
    padding: 8px;
    border: none;
    outline: none;
    color: var(--neutral-color-dark-gray, #A6A8AA);
}

.forgot--password--input:hover {
    color: var(--primary-color-hover, #992000);
}

.button--forgot--password--submit {
    width: 400px;
    height: 36px;
    background-color: var(--primary-color-orange-sanguine, #FF3300);
    color: var(--neutral-color-white, #FFFFFF);
    font-family: var(--secondary-font, "Manrope");
    text-transform: uppercase;
    padding: 8px;
    margin-top: 32px;
    border: none;
}

.button--forgot--password--submit:hover {
    background-color: var(--primary-color-hover, #992000);
}

/* .button--forgot--password--submit:disabled {
    background: var(--neutral-color-mid-gray-50)
} */

.button--forgot--password--send {
    width: 400px;
    height: 36px;
    background-color: transparent;
    color: var(--primary-color-orange-sanguine, #FF3300);
    background-color: var(--opacity-color-pale-peach-50, #FFE0D180);
    font-family: var(--secondary-font, "Manrope");
    /* text-transform: uppercase; */
    padding: 8px;
    margin-top: 32px;
    border: 1px solid var(--primary-color-orange-sanguine, #FF3300);
}

.button--forgot--password--send:hover {
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
}

.forgot--password--send--disabled {
    color: var(--neutral-color-darker-gray, #5B5E62);
    background-color: var(--primary-color-pale-peach-50, #FFEFE8);
    border: 1px solid var(--neutral-color-mid-gray, #DADADC);
}

.button--forgot--password--false {
    padding: 8px;
    border: none;
    background-color: transparent;
    color: var(--primary-color-claret, #451B17);
}

.button--forgot--password--true {
    padding: 8px;
    border: none;
    background-color: transparent;
    color: var(--primary-color-orange-sanguine, #FF3300); 
}

.button--forgot--password--true:hover,
.button--forgot--password--false:hover {
    color: var(--primary-color-hover, #992000);
}
