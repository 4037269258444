
.logout--tittle {
    color: var(--primary-color-claret, #451B17);
    font-weight: 500;
    font-size: medium;
}

/* Fondo transparente que ocupa toda la pantalla */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color-claret-70, #451B17B2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

/* Contenido del modal */
.modal-content {
    /* background-color: white; */
    background: var(--primary-color-pale-peach-50, #FFEFE8);
    padding: 20px;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    text-align: center;
    width: 350px;
}

/* Botones en el modal */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* .confirm-btn, 
.cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
} */

.button--logout--confirm,
.button--logout--cancel {
    /* border: none; */
    height: 32px;
    width: 200px;
    font-family: var(--secondary-font, "Manrope"); 
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 20px;
    padding-bottom: 25px;
    margin: 4px;
}

.button--logout--cancel {
    border: 1px solid var(--primary-color-orange-sanguine, #FF3300);
    background: var(--primary-color-pale-peach-50, #FFEFE8);
    color: var(--primary-color-orange-sanguine, #FF3300);
}

.button--logout--cancel:hover {
    border-color: black;
    color: black;
    background: var(--primary-color-pale-peach-50, #FFEFE8);
}

.button--logout--confirm {
    border: none;
    background: var(--primary-color-orange-sanguine, #FF3300);
    color: white;
    padding-bottom: 27px;
}

.button--logout--confirm:hover {
    background: var(--primary-color-hover, #992000);
}

.confirm-btn {
    background-color: #f44336; /* Rojo para confirmar */
    color: white;
}

.cancel-btn {
    background-color: #ccc; /* Gris para cancelar */
    color: black;
}

.confirm-btn:hover {
    background-color: #d32f2f;
}

.cancel-btn:hover {
    background-color: #aaa;
}
